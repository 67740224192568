<template>
  <ion-content class="">
    <div class="flex items-center h-full">
      <div class="flex-1">
        <div class="ion-text-center flex justify-center mb-5">
          <router-link :to="{ name: 'news' }">
            <ion-img src="/img/logo.svg" class="logo" />
          </router-link>
        </div>

        <form @submit.prevent="submitResendVerification">
          <div class="ion-padding">
            <ion-item lines="none">
              <ion-text text-wrap>
                {{ $t('verificationmailexpired') }}
              </ion-text>
            </ion-item>
            <ion-item>
              <ion-input type="email" :placeholder="$t('email')" :value="email" @ionInput="email = $event.target.value" />
            </ion-item>
          </div>

          <div class="ion-padding">
            <Error :error="error" />

            <div v-if="success && !error">
              {{ $t('newverificationmailsendsuccess', { email }) }}
            </div>

            <LoadingButton color="moto" :disabled="loading" :loading="loading" type="submit">
              {{ $t('resendverificationmail') }}
            </LoadingButton>
            <ion-button type="button" expand="full" @click="$router.push({ name: $routeNames.LOGIN })">
              {{ $t('backtologin') }}
            </ion-button>
          </div>
        </form>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { RESEND_VERIFICATION_MAIL } from "@/graphql/mutations";

export default {
  data() {
    return {
      email: '',
      error: null,
      loading: false,
      success: false,
    }
  },
  methods: {
    async submitResendVerification() {
      this.error = null;
      this.success = false;
      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: RESEND_VERIFICATION_MAIL,
          variables: {
            email: this.email
          }
        });

        this.success = true;
      } catch (error) {
        this.error = error;
      }

      this.loading = false;
    },
  },
};
</script>
